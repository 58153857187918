const modal = document.querySelector(".modal");
const alert = document.querySelector(".alert-iban");
const overlay = document.querySelector(".overlay");
const openModalBtn = document.querySelector(".btn-open");
const closeModalBtn = document.querySelector(".btn-close");
const ibanBtn = document.querySelector(".iban-btn");

const openModal = function () {
    modal.classList.remove("hidden");
    overlay.classList.remove("hidden");
};

openModalBtn.addEventListener("click", openModal);

const closeModal = function () {
    modal.classList.add("hidden");
    overlay.classList.add("hidden");
};

closeModalBtn.addEventListener("click", closeModal);
overlay.addEventListener("click", closeModal);
 
const openAlert = function () {
    alert.classList.remove("hidden");
    overlay.classList.remove("hidden");
};

const closeAlert = function () {
    alert.classList.add("hidden");
    overlay.classList.add("hidden");
};

const ibanCopy = () => {
    // Get the text field
    const copyText = document.getElementById("iban"); 
    copyText.select();
    copyText.setSelectionRange(0, 99999);  
    navigator.clipboard.writeText(copyText.value);
    openAlert() 
    setTimeout(closeAlert, 3000);
  }

   ibanBtn.addEventListener("click", ibanCopy);
